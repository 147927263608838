export type Host = {
	name: string;
	hostname: string;
	link: string;
	img: string;
};

export const hosts = [
	{
		name: "Nascer",
		hostname: "nascer",
		link: "http://sebrae.sc/",
		img: "/img/footer/logo_nascer.svg",
	},
	{
		name: "InPETU Hub",
		hostname: "inpetuhub",
		link: "https://inpetuhub.sites.ufsc.br/",
		img: "/img/footer/logo_inpetu.png",
	},
	{
		name: "Inova Seed",
		hostname: "inovaseed",
		link: "https://inovacao.ufrj.br/inovaseed-o-programa-de-empreendedorismo-da-ufrj-para-alavancar-ideias-e-projetos-de-inovacao/",
		img: "/img/footer/logo_inovaUFRJ.png",
	},
];
